@import 'styles/config/variables';

.gradient-preview {
  overflow: hidden;
  height: 220px;
  border-radius: $container-border-radius;
  background-color: $white;
  background-image: linear-gradient(
    45deg
    ,$black-squeeze 25%,transparent 0),linear-gradient(
    -45deg
    ,$black-squeeze 25%,transparent 0),linear-gradient(
    45deg
    ,transparent 75%,$black-squeeze 0),linear-gradient(
    -45deg
    ,transparent 75%,$black-squeeze 0);
  background-size: 16px 16px;
  background-position: 0 0, 0 8px, 8px -8px, -8px 0px;

  &__body {
    height: 220px;
  }
}
