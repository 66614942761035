@import 'styles/config/variables';
@import 'styles/utils/mixins';

.edit-member-page {
  padding: 30px;

  &__input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  &__input-label {
    margin-right: 6px;
  }

  &__info,
  &__input-group,
  &__role-label,
  &__input-label {
    margin-bottom: 20px;
    padding: 0;
    font-size: 16px;

    @include breakpoint(min, width, $medium) {
      font-size: 20px;
    }
  }

  &__input-group {
    margin-right: 30px;
  }

  &__input {
    margin-bottom: 30px;
  }

  &__role-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__status-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0;
  }

  &__status-label {
    font-size: 16px;

    @include breakpoint(min, width, $medium) {
      font-size: 20px;
    }
  }

  &__resend-button,
  &__deactivate-button {
    margin-left: 40px;
  }

  &__checkbox {
    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-radius: 4px;
      border: 1px solid $black;
    }

    &--checked {
      &:before {
        background: url('../../../assets/images/checkbox.svg') no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
  }

  &__notification {
    margin: 0 0 20px;
  }
}
