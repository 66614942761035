@import 'styles/config/variables';
@import 'styles/utils/mixins';

.announcement-form {
  $self: &;

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 30px;
  }

  &__input {
    display: block;
    margin-bottom: 30px;
  }

  &__email-notification {
    position: relative;
    margin-bottom: 20px;
  }

  &__checkbox {
    opacity: 0;
    position: absolute;
    top: 0;
    cursor: pointer;
  }

  &__checkbox-label {
    display: flex;
    flex-direction: row-reverse;
    align-content: baseline;
    font-weight: 700;
    margin-right: 10px;

    &:before {
      content: '';
      display: inline-block;
      min-width: 20px;
      height: 20px;
      margin-left: 30px;
      border-radius: 4px;
      border: 1px solid $black;
    }

    &--checked {
      &:before {
        background: url('../../../assets/images/checkbox.svg') no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
  }

  &__upload-wrapper {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 20px 0;
  }

  &__image-label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__reset-image-button {
    margin: 0 40px;
    padding: 0;
    font-size: 14px;
    line-height: 14px;
  }

  &__upload-image-button {
    width: 220px;
    height: 150px;
    margin-right: 30px;
    padding: 0;
    border: none;
    border-radius: $container-border-radius;
    color: $silver-rust;
    background-color: transparent;
    box-shadow: $container-box-shadow;
    font-family: $main-font;
    font-size: 15px;
    cursor: pointer;

    &--invalid {
      border: 1px solid $error;
    }
  }

  &__upload-image-icon {
    max-width: 30px;
    margin-bottom: 10px;
  }

  &__upload-label {
    margin-bottom: 16px;
  }

  &__dropzone {
    flex-direction: column;
    width: 220px;
    height: 150px;
    margin: 0;
    border-radius: $container-border-radius;
    background-image: none;
    background-color: transparent;
    box-shadow: $container-box-shadow;
    color: $silver-rust;
    font-size: 15px;
  }

  &__image-preview-wrapper {
    overflow: hidden;
    width: 220px;
    height: 150px;
  }

  &__image-preview {
    object-fit: cover;

    #{$self}__upload-image-button {
      padding: 0;
    }
  }

  &__files-list {
    margin: 20px 0;
    list-style-type: none;
  }

  &__files-item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__notification {
    margin: 0 0 20px;
  }
}
