.settings {
  padding: 16px;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__section {
    margin: 16px;
  }
}
