@import 'styles/config/variables';

.event-info {
  padding: 6px;
  border: 1px solid $alto;
  border-radius: 6px;
  background-color: $white;

  &:not(:last-child) {
    margin-right: 6px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $black;
    font-size: 12px;
  }

  &__title-label {
    margin-left: 6px;
  }

  &__kbd {
    padding: 4px 6px;
    border-radius: 4px;
    background-color: $cod-grey;
    color: $white;
    font-size: 12px;
    line-height: 12px;
  }

  &__boolean {
    color: $error;
    font-weight: 700;

    &--false {
      color: $mine-shaft;
      font-weight: 500;
    }
  }

  &__deprecation,
  &__space {
    color: $mine-shaft;
  }

  &__deprecation {
    margin-left: 6px;
  }
}
