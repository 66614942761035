@import 'styles/config/variables';
@import 'styles/utils/mixins';

.track {
  display: inline-block;
  position: relative;
  height: 10px;
  margin: 20px 0 40px;
  border-radius: 4px;
  user-select: none;
  background-color: $alto;

  @include breakpoint(min, width, $small) {
    margin: 20px 0;
  }

  &__active {
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: 4px;
    background-color: $black;
    user-select: none;
  }

  &__thumb {
    content: '';
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: $container-box-shadow;
    user-select: none;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}
