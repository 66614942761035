@import 'styles/config/variables';

.keycap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  min-width: 40px;
  min-height: 40px;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid $alto;
  background-color: $white;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;

  &--control {
    width: 62px;
  }

  &--shift {
    width: 82px;
  }

  &--alt {
    width: 52px;
  }

  &--meta,
  &--space,
  &--enter {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      text-align: center;
      line-height: 40px;
      font-size: 24px;
    }
  }

  &--space {
    position: relative;
    width: 182px;

    &::before {
      content: '[';
      transform: rotate(-90deg);
    }
  }

  &--enter {
    width: 82px;

    &::before {
      content: '↵';
    }
  }

  &--meta {
    width: 52px;

    &::before {
      content: '⌘';
    }
  }
}
