@import 'styles/config/variables';
@import 'styles/utils/mixins';

$grid-gutter: 15px;

.dashboard {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;

  &__card {
    width: 100%;
    margin: $grid-gutter;
    min-height: 420px;
    border-radius: $container-border-radius;
    box-shadow: $container-box-shadow;

    @include breakpoint(min, width, $extra-large) {
      width: calc(50% - #{$grid-gutter * 2});
    }
  }

  &__card-title {
    padding: 30px;
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;

    &:before,
    &:after {
      display: inline-block;
    }

    &:before {
      content: '<';
      margin-right: 10px;
    }

    &:after {
      content: '/>';
      margin-left: 10px;
    }
  }

  &__wrapper {
    padding: 0 30px;
  }
}
