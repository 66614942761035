@import 'styles/config/variables';
@import 'styles/utils/mixins';

$grid-gutter: 30px;
$grid-gutter-large: 60px;

.annoucement-article {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
  }

  &__text-wrapper,
  &__image-wrapper {
    width: 100%;

    @include breakpoint(min, width, $small) {
      width: calc(50% - (#{$grid-gutter} / 2));
    }
  }

  &__text-wrapper {
    min-height: 200px;
    margin: 0 0 30px;
    padding-right: 30px;

    @include breakpoint(min, wdith, $small) {
      margin: 0 $grid-gutter 0;
    }

    @include breakpoint(min, wdith, $extra-large) {
      margin-right: $grid-gutter-large;
    }
  }

  &__image-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    min-height: 200px;
    border-radius: $container-border-radius;
    box-shadow: $container-box-shadow;
  }

  &__image {
    width: 100%;
    object-fit: cover;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 700;
  }

  &__description {
    font-size: $content-font-size;

    @include breakpoint(min, width, $extra-large) {
      font-size: 20px;
    }
  }

  &__avatar-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0;
  }

  &__avatar {
    margin-right: 20px;
  }

  &__author-name {
    font-size: 18px;
    line-height: 24px;

    @include breakpoint(min, width, $extra-large) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &__author-position {
    font-size: 16px;
    line-height: 18px;

    @include breakpoint(min, width, $extra-large) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__files-wrapper {
    padding: 30px;
    margin-top: 60px;
  }

  &__files-label {
    font-size: 24px;
    font-weight: 700;
  }

  &__list {
    list-style-type: none;
  }


  &__item {
    margin: 10px 0;
  }

  &__download-button {
    &,
    &:visited {
      color: $black;
    }
  }

  &__date {
    margin-top: 20px;
    font-size: 12px;
    text-transform: lowercase;
  }
}
