@import 'styles/config/variables';
@import 'styles/utils/mixins';

.create-password-form {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }

  &__title {
    margin: 30px auto;
    text-align: center;
    font-size: 40px;
    line-height: 50px;

    @include breakpoint(min, width, $large) {
      font-size: 84px;
      line-height: 110px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    box-shadow: 0px 0px 6px rgba($black, 0.25);
    border-radius: 2px;

    @include breakpoint(min, width, $medium) {
      width: 500px;
      margin: 0 auto;
      padding: 30px;
    }
  }

  &__avatar {
    margin: 20px auto 30px;

    @include breakpoint(min, width, $large) {
      margin: 40px auto 50px;
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(min, width, $medium) {
      min-width: 430px;
    }
  }

  &__label {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 14px;
    transition-property: color;
    transition: $transition;

    &--invalid {
      color: $error;
    }
  }

  &__input {
    margin-bottom: 30px;
  }

  &__hint {
    display: inline;
    min-height: 20px;
    color: $error;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
  }
}
