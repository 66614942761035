.css-cursors-generator {
  margin: 10px;
  padding: 20px;

  &__buttons {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__group {
    margin-top: 30px;
  }

  &__button {
    min-width: 60px;
  }

  &__title {
    margin-bottom: 10px;
  }
}
