@import 'styles/config/variables';
@import 'styles/utils/mixins';

.page-dividers-settings {
  padding: 20px;
  margin: 10px;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
  }

  &__group {
    flex: 1 0 calc(32% - 20px);
    margin: 10px;
  }

  &__input {
    & + & {
      margin-top: 10px;
    }
  }

  &__label {
    color: $black;
    font-size: 14px;
    margin-bottom: 6px;
  }

  &__hex-input {
    input {
      flex: 1;
    }
  }
}
