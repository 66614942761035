@import 'styles/config/variables';
@import 'styles/utils/mixins';

.gradients-gallery {
  margin-top: 40px;
  padding: 16px;

  &__title {
    margin: 10px;
    text-align: center;
  }
  
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 6px;
  }
  
  &__item {
    margin: 6px;
    flex: 0 0 1;
    
    @include breakpoint (min, width, $medium) {
      flex: 0 0 calc(33.3333% - 60px);
    }
  }
}

