@import 'styles/config/variables';

.color-stop {
  position: absolute;
  top: -4px;
  transform: translateX(-8px);

  &__handle {
    overflow: hidden;
    width: 16px;
    height: 66px;
    border-radius: $container-border-radius;
    box-shadow: $container-box-shadow;
    cursor: col-resize;
  }

  &__handle-color {
    width: 100%;
    height: 100%;
    border: 2px solid $white;
    border-radius: 14px;
  }
}
