@import 'styles/config/variables';

.event-keycode-page-header {
  margin-bottom: 16px;
  padding: 30px;

  &__title {
    margin: 20px 0;
    border-radius: $container-border-radius;
    color: $black;
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
  }

  &__description {
    margin: 20px 0 0;
    color: $mine-shaft;
  }

  &__placeholder {
    text-align: center;
  }

  &__keys {
    display: flex;
  }

  &__key {
    display: flex;
    align-items: center;
  }

  &__separator {
    margin: 0 16px;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
  }
}
