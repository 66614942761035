@import 'styles/config/variables';

.page-dividers-preview {
  position: relative;
  overflow: hidden;
  height: 500px;
  margin: 30px;
  border-radius: $container-border-radius;
  background-image: linear-gradient(to right, $deep-blush 0%, $apricot 100%);

  &__shape-wrapper {
    overflow: hidden;
    position: absolute;
    right: 0;
    left: 0;
    border-top-right-radius: $container-border-radius;
    border-top-left-radius: $container-border-radius;
    transition-property: transform, top, bottom;
    transition: $transition;
  
    &--top {
      top: 0;
      transform: rotate(0deg);
    }

    &--top-reverse {
      transform: rotate(0deg) scaleX(-1);
    }

    &--bottom {
      bottom: 0;
      transform: rotate(180deg);
    }

    &--bottom-reverse {
      transform: rotate(180deg) scaleX(-1);
    }
  }
}
