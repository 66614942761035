@import 'styles/config/variables';

.tabs {
  display: flex;
  width: 100%;

  $self: &;

  &__button {
    flex: 1;
    height: 36px;
    padding: 0 16px;
    border: 1px solid $alto;
    color: $mine-shaft;
    background-color: $white;
    outline: 0;
    white-space: nowrap;
    appearance: none;
    cursor: pointer;
    user-select: none;
    font-size: 18px;
    font-family: $main-font;
    transition: $transition;
    transition-property: transform;

    &:hover {
      color: $white;
      background-color: $black;
    }

    &:active {
      transform: translateY(1px);
    }

    & + & {
      border-left: 0;
    }

    &:last-of-type {
      border-top-right-radius: $container-border-radius;
      border-bottom-right-radius: $container-border-radius;
    }

    &:first-of-type {
      border-top-left-radius: $container-border-radius;
      border-bottom-left-radius: $container-border-radius;
    }

    &--active {
      color: $white;
      background-color: $black;
    }
  }

  &--no-border {
    #{$self}__button {
      border: none;
    }
  }
}
