@import 'styles/config/variables';

.svg-input {
  &__background {
    max-width: 100%;
    padding: 30px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__title {
    margin-bottom: 0;
  }

  &__input {
    outline: 0;
    width: 100%;
    height: 100px;
    padding: 10px 16px;
    border-radius: $container-border-radius;
    border: none;
    box-shadow: $container-box-shadow;
    resize: none;
    font-family: $main-font;

    &:focus {
      border-color: $black;
    }

    &::placeholder {
      color: $alto;
    }
  }

  &__errors {
    margin-top: 10px;
    color: $black;
    font-size: 14px;
  }

  &__error {
    margin: 0;

    & + & {
      margin-top: 10px;
    }
  }
}
