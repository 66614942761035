@import 'styles/config/variables';

.hex-input {
  position: relative;

  &__wrapper {
    display: flex;
    position: relative;
  }

  &__control {
    height: 36px;
    width: 36px;
    border: 1px solid $alto;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    outline: 0;
    cursor: pointer;
  }

  &__hash {
    position: absolute;
    top: 6px;
    left: 46px;
    pointer-events: none;
    font-size: 16px;
    font-weight: 500;
  }

  &__input {
    max-width: 120px;
    height: 36px;
    padding: 0 16px 0 28px;
    border: 1px solid $silver-rust;
    border-left: 0;
    border-radius: 0 4px 4px 0;
    background-color: $white;
    font-family: $main-font;
    font-size: 18px;

    &:focus {
      outline: 0;
      border-color: $mine-shaft;
    }
  }

  &__picker {
    position: absolute !important;
    top: 40px;
    left: 0;
    z-index: 10;
    border-radius: $container-border-radius;
    box-shadow: $container-box-shadow;
  }
}
