@import 'styles/config/variables';

.lorem-ipsum-preview {
  padding: 30px;

  &__item {
    color: $black;
    font-size: 16px;
    line-height: 24px;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
