@import 'styles/config/variables';

.event-keycode-page {
  margin: 10px;
  padding: 20px;

  &__title {
    margin: 0;
    padding: 10px 16px;
    border-radius: $container-border-radius;
    color: $black;
    background-color: $white;
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
  }

  &__event {
    width: 100%;
    padding: 30px;
  }

  &__event-title {
    margin-bottom: 60px;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
  }

  &__group {
    margin-top: 30px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
  }

  &__info-item {
    flex: 1;
  }

  &__output {
    padding: 16px;
  }
}
