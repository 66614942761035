@import 'styles/config/variables';

.triangle-generator {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $white;

  &__row {
    margin: 30px;
  
    & + & {
      margin-top: 0;
    }
  
    &:last-of-type {
      flex: 1;
    }
  }
}
