@import 'styles/config/variables';
@import 'styles/utils/mixins';

.compressed-result {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 30px;
  padding: 20px;
  box-shadow: $container-box-shadow;

  @include breakpoint(min, width, $small) {
    flex-wrap: nowrap;
  }

  &__preview {
    max-width: 200px;
    margin: 0 auto 30px;

    @include breakpoint(min, width, $small) {
      margin: 0 30px 0 0;
    }
  }

  &__svg-wrapper {
    svg {
      max-width: 160px;
      max-height: 160px;
    }
  }

  &__result {
    overflow: hidden;
    max-width: 90%;

    @include breakpoint(min, width, $large) {
      max-width: 70%;
    }
  }

  &__code-container {
    position: relative;
  }

  &__code {
    overflow-x: hidden;
    display: block;
    max-height: 300px;
    padding: 16px 0;
    border-radius: $container-border-radius;
    background-color: $white;
    color: $black;
    font-size: 12px;
    line-height: 14px;
  }

  &__name {
    margin-top: 10px;
    color: $black;
    text-align: center;
    font-size: 14px;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  &__controls-label {
    margin-top: 16px;
    color: $black;
    font-size: 14px;
  }

  &__control {
    width: 100%;
    min-width: 300px;
    margin-top: 10px;
    text-align: center;
  }

  &__controls-group {
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }

  &__controls-group-item {
    margin-left: 12px;
  }
}
