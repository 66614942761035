@import 'styles/config/variables';
@import 'styles/utils/mixins';

$tablet-colums: 2;
$laptop-columns: 3;
$grid-gutter: 16px;

.members-list-page {
  position: relative;
  height: 100vh;
  padding: 30px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
  }

  &__item {
    position: relative;
    width: 100%;
    max-width: 260px;
    min-height: 420px;
    margin: $grid-gutter auto;
    padding: 10px;
    border-radius: $container-border-radius;
    box-shadow: $container-box-shadow;
    transition-property: transform;
    transition: $transition;

    @include breakpoint(min, width, $medium) {
      width: calc((100% - #{$tablet-colums} * #{$grid-gutter}) / #{$tablet-colums});
      margin: $grid-gutter;
    }

    @include breakpoint(min, width, $extra-large) {
      width: calc((100% - #{$laptop-columns} * #{$grid-gutter}) /#{$laptop-columns});
    }
  }
}
