@import 'styles/config/variables';
@import 'styles/utils/mixins';

.slider-input {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include breakpoint(min, width, $small) {
    flex-wrap: nowrap;
  }

  &__input {
    min-width: 50px;
    margin-right: 16px;
    padding: 0 6px;
    text-align: center;
    font-family: $main-font;
    font-size: 18px;
  }
}
