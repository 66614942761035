@import 'styles/config/variables';
@import 'styles/utils/mixins';

$title-height: 100px;
$navigation-width: 400px;

.system-layout {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $white;

  @include breakpoint(min, width, $large) {
    flex-direction: row;
  }

  &__wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 5;
    min-height: 100px;
    padding: 20px 30px;
    text-align: right;
    box-shadow: $container-box-shadow;

    @include breakpoint(min, width, $large) {
      justify-content: flex-start;
    }

    @include breakpoint(min, width, $large) {
      padding: 20px 60px 24px;
    }
  }

  &__title {
    display: none;
    margin-right: 0;

    @include breakpoint(min, width, $medium) {
      display: inline;
    }
  }

  &__back-button {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  &__arrow {
    display: none;
    position: absolute;
    top: 36px;
    left: 30px;
    width: 26px;
    transition: $transition;
    transition-property: transform;

    @include breakpoint(min, width, $large) {
      display: block;
    }

    &:hover {
      transform: translate(-6px);
    }
  }

  &__content {
    flex-grow: 1;
    max-width: 100%;
    height: 100vh;

    @include breakpoint(min, width, $large) {
      max-width: calc(100% - #{$navigation-width});
    }
  }

  &__page {
    overflow-x: hidden;
    max-height: calc(100vh - #{$title-height});
  }

  &__button {
    margin-left: 10px;

    @include breakpoint(min, width, $large) {
      margin-left: 30px;
    }
  }
}

.notification {
  position: absolute;
  top: $title-height;
  left: 0;
  z-index: 3;
  width: 100%;
  text-align: left;

  &__message {
    padding: 4px 30px;
    font-size: $content-font-size;
    font-weight: 700;
  }

  &--unautorized,
  &--hidden {
    visibility: hidden;
    opacity: 0;
  }

  &--error {
    color: $white;
    background-color: rgba($error, 0.7);
  }

  &--success {
    background-color: rgba($black, 0.7);
    color: $white;
  }
}
