@import 'styles/config/variables';
@import 'styles/utils/mixins';

.lorem-ipsum-settings {
  padding: 30px;

  &__tabs {
    flex-wrap: wrap;

    & * {
      min-width: 100%;

      @include breakpoint(min, width, $large) {
        min-width: unset;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 16px;
  
    @include breakpoint(min, width, $large) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__input-label {
    margin-bottom: 16px;
    color: $black;

    @include breakpoint(min, width, $small) {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

  &__button {
    margin-top: 16px;

    @include breakpoint(min, width, $large) {
      margin-top: 0;
    }
  }
}
