@import 'styles/config/variables';
@import 'styles/utils/mixins';

.add-member-form {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 30px;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  &__input-label {
    margin-right: 6px;
    font-size: 16px;
  }

  &__input {
    margin-bottom: 30px;
  }

  &__notification {
    margin: 0 0 20px;
  }
}
