@import 'styles/config/variables';

$cell-size: 60px;

.direction-picker {
  display: flex;
  flex-wrap: wrap;
  width: $cell-size * 3 + 30px;
  margin: 0 -4px;

  $self: &;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $cell-size;
    height: $cell-size;
    margin: 4px;
    padding: 0 16px;
    border-radius: $container-border-radius;
    border: 1px solid $mine-shaft;
    background-color: $white;
    outline: 0;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }

  &__angle {
    width: 18px;
    height: 18px;
  }

  &__blank {
    margin: 4px;
    width: $cell-size;
    height: $cell-size;
    background-color: $black;
    border-radius: $container-border-radius;
  }
}
