@import 'styles/config/variables';

.fake-data-output {
  padding: 16px;
  margin: 10px;

  &__item {
    display: flex;
    padding: 8px 0;
  }

  &__key {
    width: 160px;
    color: $black;
  }
}
