@import 'styles/config/variables';
@import 'styles/utils/mixins';

.avatar {
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border: 1px solid $black;

  $self: &;

  &__placeholder {
    min-width: 100px;
    min-height: 100px;

    @include breakpoint(min, width, $large) {
      min-width: 180px;
      min-height: 180px;
    }
  }

  &__image {
    object-fit: cover;
    width: 180px;
    height: 180px;
  }

  &__placeholder {
    padding: 30px;
  }

  &--circle {
    border-radius: 50%;
  }

  &--small {
    #{$self}__placeholder,
    #{$self}__image {
      width: 60px;
      min-width: unset;
      height: 60px;
      min-height: unset;
    }

    #{$self}__placeholder {
      padding: 14px;
    }
  }

  &--member {
    border: 1px solid $bombay;
    border-radius: $container-border-radius;

    #{$self}__placeholder {
      path {
        stroke: $bombay;
      }
    }
  }
}
