@import 'styles/config/variables';

.button-tool {
  display: block;
  appearance: none;
  padding: 0 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: 0;
  background-color: transparent;
  text-decoration: none;
  letter-spacing: 0.75px;
  font-size: 16px;
  font-family: $main-font;
  line-height: 30px;
  font-weight: 700;
  cursor: pointer;
  transition: $transition;
  transition-property: transform, color;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.97);
  }

  &[disabled] {
    pointer-events: none;
    cursor: default;
  }

  &--white {
    color: $white;
  }

  &--black {
    color: $black;
  }
}
