@import 'styles/config/variables';

.gradient-settings {
  display: flex;
  flex-direction: column;

  &__field {
    flex: 1;

    & + & {
      margin-top: 16px;
    }
  }

  &__label {
    font-size: 14px;
    margin-bottom: 8px;
    color: $black;
  }
}
