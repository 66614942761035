@import 'styles/config/variables';
@import 'styles/utils/mixins';

.symbols-collection {
  position: relative;
  margin: 10px;
  padding: 20px;

  &__input {
    display: block;
    width: 100%;
    height: 40px;
    margin-top: 16px;
    border-radius: 6px;
  }

  &__results {
    position: relative;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    margin-top: 16px;
  }

  &__heading {
    position: sticky;
    top: 0px;
    z-index: 5;
    height: 36px;
    padding: 0 16px;
    border-bottom: 1px solid $alto;
    text-align: left;
    color: $white;
    background-color: $black;
  }

  &__tabs {
    flex-wrap: wrap;

    & * {
      min-width: 100%;

      @include breakpoint(min, width, $large) {
        min-width: unset;
      }
    }
  }
}

.symbols-collection-item {
  &__name {
    font-size: 14px;
    color: $black;
    padding: 0 16px;
  }

  &__button {
    width: 100%;
    height: 40px;
    text-align: left;
    padding: 0 16px;
    outline: 0;
    background-color: $white;
    position: relative;
    z-index: 2;
    border: 0;
    color: $black;
    font-family: $main-font;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      z-index: 3;
      color: $white;
      background-color: $black;

      &::before {
        opacity: 1;
        color: $white;
      }
    }

    &::before {
      opacity: 0;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      padding: 10px;
      border-radius: 0;
      color: $black;
      pointer-events: none;
      text-align: center;
      font-size: 16px;
      transition: $transition;
      transition-property: opacity, transform;

      @include breakpoint(min, width, $medium) {
        content: attr(data-content);
      }
    }
  }
}
