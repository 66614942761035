@import 'styles/config/variables';

$light-gradient: linear-gradient(45deg, $black-squeeze 0%, $white 100%);
$dark-gradient: linear-gradient(225deg, $mine-shaft 0%, $cod-grey 100%);

.triangle-preview {
  position: relative;
  height: 340px;
  box-shadow: $container-box-shadow;
  background-color: $white;

  &__triangle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &__button {
    position: absolute;
    z-index: 3;
    top: 10px;
    right: 10px;
    height: 36px;
    padding: 0 16px;
    border: 0;
    border-radius: $container-border-radius;
    cursor: pointer;
    font-family: $main-font;

    &--light {
      color: $black;
    }

    &--dark {
      color: $white;
    }
  }

  &--light {
    background-image: $light-gradient;
  }

  &--dark {
    background-image: $dark-gradient;
  }
}
