@import 'styles/config/variables';
@import 'styles/utils/mixins';

$grid-gutter: 16px;
$colums: 2;
$padding: 30px;
$title-heigth: 100px;
$button-height: 32px;

.announcement-list {
  padding: $padding;

  &__list {
    overflow: auto;
    max-height: calc(100vh - #{$title-heigth} - (#{$padding} * 2 ) - #{$button-height});
    list-style-type: none;
  }

  &__scroller {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    width: 100%;
    margin-bottom: $grid-gutter;

    @include breakpoint(min, width, $medium) {
      width: calc((100% - #{$colums} * #{$grid-gutter * 2}) / #{$colums});
      margin: $grid-gutter;
    }
  }

  &__message {
    width: 100%;
    text-align: center;
  }
}
