@import 'styles/config/variables';
@import 'styles/utils/mixins';

$grid-gutter: 15px;

.add-request-page {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;

  @include breakpoint(min, width, $medium) {
    flex-wrap: nowrap;
  }

  &__button {
    width: 100%;
    max-width: 100%;
    height: 420px;
    margin: 30px $grid-gutter;
    padding: 10px;
    border: none;
    background-color: $white;
    border-radius: $container-border-radius;
    box-shadow: $container-box-shadow;
    font-size: 40px;
    line-height: 40px;
    font-family: $main-font;
    font-weight: 700;
    cursor: pointer;
    transition-property: transform;
    transition: $transition;

    @include breakpoint(min, width, $medium) {
      width: calc(50% - #{$grid-gutter});
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}
