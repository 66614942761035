@import 'styles/config/variables';

.page-dividers-output {
  padding: 20px;
  margin: 10px;

  &__section {
    & + & {
      margin-top: 30px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__title {
    margin-bottom: 0;
  }

  &__button {
    color: $black;
  }
}
