@import 'styles/config/variables';
@import 'styles/utils/mixins';

.annual-leave-request-form {
  padding: 30px;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__input,
  &__select {
    margin-bottom: 30px;
  }

  &__notification {
    margin: 0 0 20px;
  }
}
