@import 'styles/config/variables';
@import 'styles/utils/mixins';

.profile-page {
  $self: &;

  &__wrapper {
    padding: 30px;

    @include breakpoint(min, width, $extra-large) {
      min-width: 600px;
    }
  }

  &__dropzone {
    margin: 0 30px 0 0;
  }

  &__upload-button {
    display: inline;
    margin: 8px 0;
  }

  &__avatar-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: 20px;

    @include breakpoint(min, width, $large) {
      flex-wrap: nowrap;
    }
  }

  &__avatar {
    width: 100px;
    height: 100px;

    @include breakpoint(min, width, $large) {
      width: 176px;
      height: 176px;
    }
  }

  &__upload-button,
  &__title,
  &__details,
  &__language-switcher,
  &__input {
    font-size: 16px;
    font-weight: 500;

    @include breakpoint(min, width, $large) {
      font-size: 24px;
    }
  }

  &__title {
    margin-right: 20px;
    font-weight: 700;
  }

  &__detail {
    padding: 8px 0;
  }

  &__input {
    display: block;
    margin: 20px 0;

    &--phone {
      width: 300px;
    }

    &--hidden {
      display: none;
    }
  }

  &__signature-arrow {
    max-width: 24px;
    transition: $transition;
    transition-property: transform;
  }

  &__signature-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 0 30px;
    padding: 0;
    border: none;
    background-color: transparent;
    font-family: $main-font;
  }

  &__template-wrapper {
    visibility: hidden;
    max-height: 0;
    opacity: 0;
    transition: $transition;
    transition-property: transform, opacity;
    transition-delay: 0.2s;
  }

  &__signature {
    width: 100%;
    margin: 10px 0;

    @include breakpoint(min, width, $small) {
      width: 60%;
    }

    @include breakpoint(min, width, $extra-large) {
      width: 614px;
    }

    &--expanded {
      #{$self}__template-wrapper {
        visibility: visible;
        opacity: 1;
        display: block;
        max-height: 100%;
      }

      #{$self}__signature-arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__notification {
    width: 100%;
    margin: 0 0 20px;
  }

  &__reset-button {
    margin-top: 20px;
    padding: 0;
  }
}
