.row {
  @extend .clearfix;
  width: 100%;
  max-width: $grid-width + ($spacing*2);
  margin: 0 auto;
}

[class^='col-'],
[class^='suffix'],
[class^='prefix'] {
  float: left;
  width: 100%;
  padding: 0 $spacing;
}

.col-1 { width: 8.33%; }
.col-2 { width: 16.67%; }
.col-3 { width: 25%; }
.col-4 { width: 33.3%; }
.col-5 { width: 41.67%; }
.col-6 { width: 50%; }
.col-7 { width: 58.33%; }
.col-8 { width: 66.7%; }
.col-9 { width: 75%; }
.col-10 { width: 83.33%; }
.col-11 { width: 91.67%; }

.prefix-1 { margin-left: 8.33%; }
.prefix-2 { margin-left: 16.67%; }
.prefix-3 { margin-left: 25%; }
.prefix-4 { margin-left: 33.3%; }
.prefix-5 { margin-left: 41.67%; }
.prefix-6 { margin-left: 50%; }
.prefix-7 { margin-left: 58.33%; }
.prefix-8 { margin-left: 66.7%; }
.prefix-9 { margin-left: 75%; }
.prefix-10 { margin-left: 83.33%; }
.prefix-11 { margin-left: 91.67%; }

.suffix-1 { margin-right: 8.33%; }
.suffix-2 { margin-right: 16.67%; }
.suffix-3 { margin-right: 25%; }
.suffix-4 { margin-right: 33.3%; }
.suffix-5 { margin-right: 41.67%; }
.suffix-6 { margin-right: 50%; }
.suffix-7 { margin-right: 58.33%; }
.suffix-8 { margin-right: 66.7%; }
.suffix-9 { margin-right: 75%; }
.suffix-10 { margin-right: 83.33%; }
.suffix-11 { margin-right: 91.67%; }

@each $breakpoint, $size in $breakpoints {
  @include breakpoint(min,width,$size) {
    .col-#{$breakpoint}-1 { width: 8.33%; }
    .col-#{$breakpoint}-2 { width: 16.67%; }
    .col-#{$breakpoint}-3 { width: 25%; }
    .col-#{$breakpoint}-4 { width: 33.3%; }
    .col-#{$breakpoint}-5 { width: 41.67%; }
    .col-#{$breakpoint}-6 { width: 50%; }
    .col-#{$breakpoint}-7 { width: 58.33%; }
    .col-#{$breakpoint}-8 { width: 66.7%; }
    .col-#{$breakpoint}-9 { width: 75%; }
    .col-#{$breakpoint}-10 { width: 83.33%; }
    .col-#{$breakpoint}-11 { width: 91.67%; }
  }
}

@each $breakpoint, $size in $breakpoints {
  @include breakpoint(min,width,$size) {
    .prefix-#{$breakpoint}-0 { margin-left: 0; }
    .prefix-#{$breakpoint}-1 { margin-left: 8.33%; }
    .prefix-#{$breakpoint}-2 { margin-left: 16.67%; }
    .prefix-#{$breakpoint}-3 { margin-left: 25%; }
    .prefix-#{$breakpoint}-4 { margin-left: 33.3%; }
    .prefix-#{$breakpoint}-5 { margin-left: 41.67%; }
    .prefix-#{$breakpoint}-6 { margin-left: 50%; }
    .prefix-#{$breakpoint}-7 { margin-left: 58.33%; }
    .prefix-#{$breakpoint}-8 { margin-left: 66.7%; }
    .prefix-#{$breakpoint}-9 { margin-left: 75%; }
    .prefix-#{$breakpoint}-10 { margin-left: 83.33%; }
    .prefix-#{$breakpoint}-11 { margin-left: 91.67%; }
  }
}

@each $breakpoint, $size in $breakpoints {
  @include breakpoint(min,width,$size) {
    .suffix-#{$breakpoint}-0 { margin-right: 0; }
    .suffix-#{$breakpoint}-1 { margin-right: 8.33%; }
    .suffix-#{$breakpoint}-2 { margin-right: 16.67%; }
    .suffix-#{$breakpoint}-3 { margin-right: 25%; }
    .suffix-#{$breakpoint}-4 { margin-right: 33.3%; }
    .suffix-#{$breakpoint}-5 { margin-right: 41.67%; }
    .suffix-#{$breakpoint}-6 { margin-right: 50%; }
    .suffix-#{$breakpoint}-7 { margin-right: 58.33%; }
    .suffix-#{$breakpoint}-8 { margin-right: 66.7%; }
    .suffix-#{$breakpoint}-9 { margin-right: 75%; }
    .suffix-#{$breakpoint}-10 { margin-right: 83.33%; }
    .suffix-#{$breakpoint}-11 { margin-right: 91.67%; }
  }
}
