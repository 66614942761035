@import 'styles/config/variables';
@import 'styles/utils/mixins';

.button {
  min-width: 100px;
  padding: 18px 24px;
  border: none;
  border-radius: 4px;
  background-color: $black;
  font-weight: 700;
  font-size: 18px;
  font-family: $main-font;
  transition: $transition;
  color: $white;
  transition-property: transform, color, background-color, box-shadow;
  cursor: pointer;

  &:hover {
    transform: translateY(1px);
    color: $black;
    background-color: $white;
    box-shadow: inset 0px 0px 0px 1px $black;
  }

  &--delete {
    background-color: $error;
  }

  &--disabled {
    background-color: $mine-shaft;
  }
}
