h1 {
  font-size: $h1-mobile;

  @include breakpoint(min, width, $small) {
    font-size: $h1;
    line-height: 46px;
  }
}

h2 {
  font-size: $h2-mobile;
  line-height: $h2-mobile;

  @include breakpoint(min, width, $extra-large) {
    font-size: $h2;
    line-height: $h2;
  }
}

h3 {
  font-size: $h3;
  line-height: $h3 + 4px;
}

h4 {
  font-size: $h4;
  line-height: $h4 + 4px;
}

h5 {
  font-size: $h5;
  line-height: $h5 + 4px;
}

h6 {
  font-size: $h6;
  line-height: $h6 + 4px;
}
