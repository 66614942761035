@import 'styles/config/variables';

.code {
  &__title {
    margin-bottom: 6px;
    color: $black;
    font-size: 18px;
  }

  &__section {
    margin-top: 16px;
  }
}
