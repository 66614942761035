@import 'styles/config/variables';
@import 'styles/utils/mixins';

.cursor-item {
  position: relative;
  height: 60px;
  margin: 0 10px 10px 0;
  border: 1px solid $alto;
  border-radius: $container-border-radius;
  color: $black;
  background-color: $white;
  outline: 0;
  font-family: $main-font;
  font-size: $content-font-size;
  line-height: 24px;

  &:hover {
    color: $white;
    background-color: $black;
  }

  &--active {
    &::after {
      content: attr(data-content);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: $container-border-radius;
      color: $white;
      background-color: $mine-shaft;
    }
  }
}
