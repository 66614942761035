@import 'styles/config/variables';

.gradient-line {
  position: relative;

  &__line {
    height: 50px;
    border-radius: 4px;
    cursor: copy;
  }

  &__line-wrapper {
    padding: 4px;
    border-radius: $container-border-radius;
    border: 1px solid $alto;
  }
}
