@import 'styles/config/variables';
@import 'styles/utils/mixins';

$title-height: 96px;

.faq {
  overflow: hidden;
  position: relative;
  min-height: calc(100vh - #{$title-height});
  padding: 30px;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__input,
  &__textarea {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid $black;
    color: $black;
    font-size: 20px;
    line-height: 30px;

    @include breakpoint(min, width, $extra-large) {
      font-size: 32px;
      line-height: 48px;
    }

    &--invalid {
      border-bottom: 1px solid $error;
    }
  }

  &__textarea {
    resize: none;
    font-size: 16px;
    line-height: 24px;

    @include breakpoint(min, width, $extra-large) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  &__add-button {
    margin: 20px 0;
  }

  &__list {
    list-style-type: none;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 56px;
    }
  }

  &__notification {
    margin: 0 0 20px;
  }
}
