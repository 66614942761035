@import 'styles/config/variables';
@import 'styles/utils/mixins';

.eu-project {
  max-width: 90%;
  margin: 30px auto;

  @include breakpoint(min, width, $large) {
    display: flex;
  }

  &__images-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    max-width: 500px;
    margin: auto;
  }

  &__image-wrapper {
    margin: 20px;
  }

  &__image {
    max-height: 50px;

    @include breakpoint(min, width, $medium) {
      max-height: 80px;
    }
  }

  &__text-wrapper {
    padding: 20px;
  }

  &__text {
    margin: 10px 0;
    font-size: 12px;
    line-height: 18px;
  }
}
