@import 'styles/config/variables';
@import 'styles/utils/mixins';

.navigation {
  position: absolute;
  z-index: 13;
  padding-top: 40px;
  transition: $transition;
  transition-property: background-color;

  @include breakpoint(min, width, $large) {
    position: relative;
    min-width: 400px;
    min-height: 100vh;
    padding-top: 0;
    background-color: $black;
  }

  $self: &;

  &__background {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: $white;

    @include breakpoint(min, width, $large) {
      display: none;
    }
  }

  &__logo {
    position: relative;
    z-index: 1;
  }

  &__wrapper {
    overflow: hidden;
    background-color: $black;
    transition: $transition;
    transition-property: transform, opacity;
    transition-delay: 0.2s;
    transform: translateX(-400px);

    @include breakpoint(min, width, $small) {
      max-width: 400px;
      transform: translateX(0);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px;

    @include breakpoint(min, width, $small) {
      justify-content: flex-start;
      min-height: 600px;
      margin-left: 34px;
      padding: 0;
    }

    @include breakpoint(min, width, $large) {
      height: calc(100vh - 110px);
    }

    &:hover {
      #{$self}__link:not(:hover) {
        @include breakpoint(min, width, $small) {
          opacity: 0.6;
        }
      }
    }
  }

  &__link {
    margin: 20px 0;
    color: $white;
    font-size: 32px;
    line-height: 32px;
    font-weight: 400;
    text-decoration: none;
    text-transform: lowercase;
    transition-property: opacity;
    transition: $transition;

    @include breakpoint(min, width, $small) {
      margin: 4px 0;
    }

    &:before,
    &:after {
      display: inline-block;
      transition-property: transform;
      transition: $transition;
    }

    &:before {
      content: '<';
      margin-right: 10px;
    }

    &:after {
      content: '/>';
      margin-left: 10px;
    }

    &:hover {
      font-weight: 700;

      &:after {
        transform: translateX(6px);
      }

      &:before {
        transform: translateX(-6px);
      }
    }

    &--active {
      font-weight: 700;
    }

    &--sign-out {
      @include breakpoint(min, width, $small) {
        position: absolute;
        bottom: 90px;
      }
    }
  }

  &__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40px;
    left: 30px;
    z-index: 3;
    border: 0;
    padding: 0;
    background-color: transparent;
    color: $black;
    font-family: $main-font;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    transition-property: color;
    transition: $transition;
    cursor: pointer;

    &:before {
      content: '<';
      margin-right: 6px;
    }

    &:after {
      content: '/>';
      margin-left: 6px;
    }
  }

  &__header-logo {
    width: 16px;
    margin-right: 6px;

    &--white {
      fill: $white;
    }
  }

  &--closed {
    min-height: 60px;

    #{$self}__wrapper {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      transform: translateX(-100%);
    }

    #{$self}__close-button {
      color: $black;
    }
  }

  &--open {
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($black, 0.6);

    #{$self}__wrapper {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }

    #{$self}__logo {
      justify-content: flex-start;
      padding: 50px 30px;
      display: none;

      @include breakpoint(min, width, $small) {
        display: flex;
      }
    }

    #{$self}__close-button {
      color: $white;
    }

    #{$self}__background {
      width: 100%;
      min-height: 100%;
      background-color: $black;

      @include breakpoint(min, width, $small) {
        width: 400px;
      }
    }
  }
}
