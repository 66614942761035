@import 'styles/config/variables';

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba($black, 0.9);
  color: $alto;
  transition: $transition;
  transition: opacity , transform ;

  &__title {
    font-size: 40px;
    font-weight: 700;
  }
}
