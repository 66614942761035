@import 'styles/config/variables';

.drawing-area {
  position: relative;
  margin-right: 30px;

  &__background {
    width: 300px;
    height: 300px;
    border-radius: $container-border-radius;
    box-shadow: $container-box-shadow;
    background-color: $alto;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  &__button {
    &:first-child {
      margin-right: 20px;
    }
  }
}
