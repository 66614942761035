@import 'styles/config/variables';
@import 'styles/utils/mixins';

.color-shades-list {
  margin: 10px;
  padding: 20px;

  $self: &;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__shades {
    display: flex;
    margin-top: 20px;
  }

  &__shade {
    flex: 1;
    overflow: hidden;
    position: relative;
    min-width: 70px;
    border: 1px solid $alto;
    background-color: transparent;
    padding: 0;
    outline: 0;
    cursor: pointer;

    &::before {
      content: attr(data-content);
      opacity: 0;
      position: absolute;
      display: block;
      top: 60px;
      right: 0;
      left: 0;
      bottom: 0;
      color: $white;
      background-color: rgba($black, 0.8);
      text-align: center;
      font-size: 14px;
      font-family: $main-font;
      line-height: 30px;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &:first-of-type {
      border-top-left-radius: $container-border-radius;
      border-bottom-left-radius: $container-border-radius;
    }

    &:last-of-type {
      border-top-right-radius: $container-border-radius;
      border-bottom-right-radius: $container-border-radius;
    }

    & + & {
      border-left: 0;
    }
  }

  &__preview {
    height: 60px;
  }

  &__value {
    display: block;
    width: 100%;
    padding: 8px 0;
    border: 0;
    border-top: 1px solid $alto;
    background-color: $white;
    font-size: 12px;
  }

  &__copy-all-button,
  &__remove-button {
    appearance: none;
    padding: 0;
    border: 0;
    color: $black;
    background-color: transparent;
    outline: 0;
    cursor: pointer;
    font-size: 14px;
    font-family: $main-font;

    &:hover {
      text-decoration: underline;
    }
  }

  &__remove-button {
    margin-left: 16px;
  }

  &__copy-all-button {
    color: $black;

    &--copied {
      pointer-events: none;
    }
  }
}
