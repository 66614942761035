@import 'styles/config/variables';

.gradient-gallery-item {
  min-width: 300px;
  border-radius: 180px;
  margin: 16px auto;
  padding: 16px;
  border-radius: $container-border-radius;
  box-shadow: $container-box-shadow;
  border: 1px solid $alto;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-top: -8px;
  }

  &__preview {
    height: 180px;
    width: 180px;
    border-radius: 180px;
    margin: 16px auto;
  }

  &__name {
    max-width: 120px;
    height: 30px;
    margin-bottom: 8px;
    color: $black;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
  }

  &__copy {
    background-color: transparent;
    cursor: pointer;
    height: 30px;
    padding: 0;
    border: 0;
    color: $mine-shaft;
    text-align: center;
    outline: 0;
    line-height: 1;
    font-size: 14px;

    &:hover {
      color: $black;
    }
  }

  &__copied {
    color: $error;
    pointer-events: none;
  }

  &__button {
    height: 30px;
    width: 100%;
    border: 0;
    background-color: transparent;
    color: $mine-shaft;
    text-align: center;
    outline: 0;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      color: $black;
    }
  }

  &__gradient-gallery-item {
    padding: 16px;
  }
}
