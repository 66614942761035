@import 'styles/config/variables';

.select {
  &__label {
    display: block;
    margin-bottom: 5px;
    color: $black;
    font-size: 14px;
  }

  &__control-wrapper {
    position: relative;
    min-width: 80px;
  }

  &__control {
    width: 100%;
    appearance: none;
  }

  &__chevron {
    opacity: 0.3;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    pointer-events: none;
  }
}
