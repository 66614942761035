@import 'styles/config/variables';
@import 'styles/utils/mixins';

.code-container {
  overflow-x: hidden;
  position: relative;
  max-height: 300px;
  padding: 30px;
  border-radius: $container-border-radius;
  box-shadow: $container-box-shadow;
  background-color: $white;
  font-size: 14px;
  line-height: 20px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($black, 0.3);
    box-shadow: inset 0 0 6px rgba($black, 0.3);
    background-color: $alto;
    border-radius: 4px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 4px;
    background-color: $alto;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid $silver-rust;
    border-radius: 4px;
    background-color: $black;
  }

  &__code {
    overflow-x: auto;
    max-width: 100%;
    margin: 0;
    background-color: $white;
    color: $black;
    white-space: pre-wrap;
    word-break: break-word;
  }

  &__copy {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    border: 0;
    color: $black;
    font-size: 16px;
    font-weight: 700;
  }
}
