@import 'styles/config/variables';
@import 'styles/utils/mixins';

$settings-breakpoint: 1200px;

.gradient-colors {
  &__header {
    display: none;

    @include breakpoint(min, width, $settings-breakpoint) {
      display: flex;
      justify-content: space-between;
    }
  }
}

.gradient-color {
  display: flex;

  & + & {
    margin-top: 16px;
  }

  &__header {
    display: flex;
  }

  &__label {
    margin-bottom: 8px;
    color: $mine-shaft;
    font-size: 14px;
  }

  &__field {
    & + & {
      @include breakpoint(min, width, $settings-breakpoint) {
        margin-left: 30px;
      }
    }

    .slider-input {
      display: none;

      @include breakpoint(min, width, $settings-breakpoint) {
        display: flex;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: 0;
    border: 0;
    color: $mine-shaft;
    background-color: transparent;
    cursor: pointer;
    outline: 0;

    &:hover {
      color: $error;
    }
  }
}
