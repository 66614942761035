@import 'styles/config/variables';

$title-heigth: 100px;
$padding: 30px;
$tab-height: 36px;

.leaves {
  padding: $padding;

  &__list {
    overflow: auto;
    max-height: calc(100vh - #{$title-heigth} - (#{$padding} * 2 ) - #{$tab-height});
    list-style-type: none;
  }

  &__item {
    margin: 10px 30px;
  }

  &__message {
    text-align: center;
  }
}
