.base64-encoding {
  overflow: hidden;
  margin: 10px;
  padding: 20px;

  &__section {
    margin-top: 30px;
  }

  &__fullscreen-drop {
    height: calc(100vh - 20px);
  }
}
