@import 'styles/config/variables';
@import 'styles/utils/mixins';

.faq-item {
  $self: &;

  &__question-wrapper {
    padding: 10px 0;
    border-bottom: 1px solid $black;
  }

  &__question {
    margin-right: 20px;
    color: $black;
    font-size: 20px;
    line-height: 30px;

    @include breakpoint(min, width, $extra-large) {
      font-size: 32px;
      line-height: 48px;
    }
  }

  &__expand-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-family: $main-font;
  }

  &__arrow {
    width: 24px;
    transition: $transition;
    transition-property: transform;

    &--rotated {
      transform: rotate(180deg);
    }
  }

  &__menu {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
  }

  &__answer {
    padding: 10px 0;
    color: $black;
    visibility: hidden;
    max-height: 0;
    opacity: 0;
    font-size: 16px;
    line-height: 24px;
    transition: $transition;
    transition-property: transform, opacity;
    transition-delay: 0.2s;

    @include breakpoint(min, width, $extra-large) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  &__answer-wrapper {
    position: relative;

    &--expanded {
      #{$self}__answer {
        visibility: visible;
        opacity: 1;
        display: block;
        max-height: 100%;
      }
    }
  }
}
