@import 'styles/config/variables';
@import 'styles/utils/mixins';

.fake-generator-settings {
  margin: 10px;
  padding: 20px;

  &__footer,
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header {
    flex-wrap: wrap;

    @include breakpoint(min, width, $medium) {
      flex-wrap: nowrap;
    }
  }

  &__button {
    flex-grow: 1;
  }

  &__footer {
    margin-top: 16px;
  }

  &__body {
    margin-top: 16px;
  }

  &__label {
    margin-bottom: 6px;
    color: $black;
    font-size: 14px;
  }

  &__field {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  &__input {
    flex: 1;

    & + & {
      margin-left: 16px;
    }
  }

  &__name-input {
    width: 100%;
  }

  &__remove-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 40px;
    margin: 16px 0;
    padding: 0;
    border: 0;
    color: $error;
    background-color: transparent;
    font-size: 14px;
    outline: 0;
    appearance: none;
    cursor: pointer;

    @include breakpoint(min, width, $small) {
      margin: 16px 0 0 16px;
      padding: 0 16px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__slider {
    margin-top: 20px;
  }
}
