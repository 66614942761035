@import 'styles/config/variables';

.gradient-code {
  margin: 10px;
  padding: 20px;

  &__section {
    & + & {
      margin-top: 20px;
    }
  }

  &__title {
    margin-bottom: 6px;
    color: $black;
    font-size: 18px;
  }
}
