@import 'styles/config/variables';
@import 'styles/utils/mixins';

$title-height: 102px;

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;
  min-height: calc(100vh - #{$title-height});
  background-color: rgba($black, 0.6);

  @include breakpoint(min, width, $large) {
    left: 400px;
  }

  &__wrapper {
    padding: 40px 20px;
    border-radius: $container-border-radius;
    background-color: $white;
  }

  &__message {
    margin-bottom: 30px;
    font-size: $content-font-size;
  }

  &__button-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__button-delete {
    margin-right: 30px;
  }
}
