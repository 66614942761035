@import 'styles/config/variables';
@import 'styles/utils/mixins';

.resolve-request {
  padding: 30px;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__select {
    margin: 10px 0;
    font-family: $main-font;
  }

  &__notification {
    margin: 0 0 20px;
  }

  &__input {
    margin-bottom: 30px;
  }
}
