@import 'styles/config/variables';

.size-picker {
  &__title {
    margin-bottom: 8px;
    color: $black;
    font-size: 16px;
  }

  &__field {
    margin-top: 16px;
  }
}
