@import 'styles/config/variables';

.settings-label {
  margin: 0 0 12px;
  padding: 0;
  color: $black;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
}
