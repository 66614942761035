@import 'styles/config/variables';
@import 'styles/utils/mixins';

.user-form {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }

  &__title {
    margin: 30px auto;
    text-align: center;
    font-size: 40px;
    line-height: 50px;

    @include breakpoint(min, width, $large) {
      font-size: 84px;
      line-height: 110px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    box-shadow: 0px 0px 6px rgba($black, 0.25);
    border-radius: 2px;

    @include breakpoint(min, width, $medium) {
      width: 500px;
      margin: 0 auto;
      padding: 30px;
    }
  }

  &__avatar-wrapper {
    width: 180px;
    height: 180px;
    margin: 20px auto 30px;

    @include breakpoint(min, width, $large) {
      margin: 40px auto 50px;
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(min, width, $medium) {
      min-width: 430px;
    }
  }

  &__label {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 14px;
    transition-property: color;
    transition: $transition;

    &--invalid {
      color: $error;
    }
  }

  &__input {
    margin-bottom: 30px;
  }

  &__remember-container {
    position: relative;
    margin-bottom: 20px;
  }

  &__checkbox {
    opacity: 0;
    position: absolute;
    top: 0;
    cursor: pointer;
  }

  &__checkbox-label {
    display: flex;
    align-content: baseline;
    font-weight: 700;

    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-radius: 4px;
      border: 1px solid $black;
    }

    &--checked {
      &:before {
        background: url('../../../assets/images/checkbox.svg') no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
  }

  &__reset-button {
    margin-bottom: 30px;
    padding: 0;
    text-align: left;
  }
}
