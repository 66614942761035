@import 'styles/config/variables';

.input {
  height: 36px;
  margin-right: 10px;
  padding: 0 16px;
  border: 1px solid $silver-rust;
  border-radius: $container-border-radius;
  background-color: $white;
  font-family: $main-font;

  &:focus {
    outline: 0;
    border-color: $mine-shaft;
  }

  &::placeholder {
    color: $black;
  }

  &--invalid {
    border-color: $error !important;
  }
}
