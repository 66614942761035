*, *:before, *:after {
  box-sizing: border-box;
  outline: none !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-decoration: none;
}

img {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  border: none;
}
