@import 'styles/config/variables';
@import 'styles/utils/mixins';

.colors-shade-generator-settings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px;
  padding: 20px;

  @include breakpoint(min, width, $extra-large) {
    flex-direction: row;
  }

  &__slider {
    margin-top: 10px;
  }

  &__label {
    margin-bottom: 6px;
    color: $black;
    font-size: 14px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin: 0 4px;
  }

  &__button {
    margin-left: 16px;
  }
}
