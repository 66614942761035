// ##################################################
// COLORS
// --------------------------------------------------
$white:                         #FFF;
$black:                         #000;
$red:                           #FF0000;
$mine-shaft:                    #333;
$alto:                          #D6D6D6;
$silver-rust:                   #CFC4C4;
$black-squeeze:                 #DFE9F3;
$cod-grey:                      #1C1C1C;
$reddish-pink:                  #FF3662;
$bombay:                        #B7BBC0;
$deep-blush:                    #ED6EA0;
$apricot:                       #ec8c69;

$main-color: $black;
$secondary-color: $white;
$error: $reddish-pink;

//--------------------------------------------------
// COLORS END
// ##################################################

// ##################################################
// GRID
// --------------------------------------------------
$grid-width:                     1200px; //Width of the container/row
$spacing:                          15px; //Left and Right padding on each column

$small: 480px;
$medium: 640px;
$large: 992px;

$breakpoints: (
  small: 480px,
  medium: 640px,
  large: 992px,
);

$small: 480px;
$medium: 640px;
$large: 992px;
$extra-large: 1300px;

// --------------------------------------------------
// GRID END
// ##################################################

// ##################################################
// FONTS
// --------------------------------------------------
$content-font-size: 16px;
$main-font: 'DINPro';

$h1: 36px;
$h1-mobile: 30px;
$h2: 48px;
$h2-mobile: 20px;
$h3: 18px;
$h4: 16px;
$h5: 14px;
$h6: 12px;

// --------------------------------------------------
// FONTS END
// ##################################################

// ##################################################
// PATHS usage: url('#{$image-dir}image.png')
// --------------------------------------------------
$image-dir:                  '../../assets/images/';
$font-dir:                    '../../assets/fonts/';

// --------------------------------------------------
// PATHS END
// ##################################################

// ##################################################
// CUSTOM VARIABLES START
// --------------------------------------------------
$transition: 0.3s ease-in;
$container-box-shadow: 0px 0px 6px rgba($black, 0.25);
$container-border-radius: 6px;

// --------------------------------------------------
// CUSTOM VARIABLES END
// ##################################################
