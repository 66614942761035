.clearfix {
  zoom: 1;

  &:before,
  &:after {
    content: '';
    display: block;
    overflow: hidden;
    height: 0;
  }

  &:after {
    clear: both;
  }
}

.hidden {
  display: none;
  visibility: hidden;
  opacity: 1;
}
