@import 'styles/config/variables';
@import 'styles/utils/mixins';

.sign-annual-leave {
  padding: 30px;

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__input {
    max-width: 90%;
    margin-bottom: 30px;
    padding: 8px 0;
    border: none;
    border-bottom: 1px solid $black;
    color: $black;
    background-color: $white;
    font-size: 20px;
    font-weight: 500;
    transition-property: border;
    transition: $transition;

    @include breakpoint(min, width, $medium) {
      width: 560px;
    }

    &::placeholder {
      color: $silver-rust;
    }

    &--invalid {
      border-bottom: 1px solid $error;

      &::placeholder {
        color: $error;
      }
    }
  }

  &__button {
    margin-top: 20px;

    @include breakpoint(min, width, $medium) {
      margin-top: 0;
    }
  }

  &__signature-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0;

    @include breakpoint(min, width, $medium) {
      flex-wrap: nowrap;
    }
  }

  &__preview {
    width: 100%;
    min-height: 500px;
    margin: 20px 0;
    border: none;
  }
}
