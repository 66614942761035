@import 'styles/config/variables';

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  margin: 30px;
  padding: 16px;
  border-radius: $container-border-radius;
  border: none;
  box-shadow: $container-box-shadow;
  background-color: $white;
  background-image: linear-gradient(-225deg, $white 0%, $alto 100%);
  color: $mine-shaft;
  font-size: 22px;

  &__input {
    visibility: hidden;
  }

  &__input-label {
    display: block;
    width: 120px;
    height: 32px;
    margin-left: 16px;
    padding: 0;
    border-radius: 4px;
    color: $mine-shaft;
    background-color: $white;
    cursor: pointer;
    text-align: center;
    font-size: $content-font-size;
    line-height: 30px;
    transition: $transition;
    transition-property: transform, box-shadow;

    &:hover {
      transform: scale(1.01) translateY(-2px);
      box-shadow: $container-box-shadow;
    }
  }
}
