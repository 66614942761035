@import 'styles/config/variables';

.swatch {
  opacity: 0.8;
  width: 18px;
  height: 18px;
  border: 0;
  border-radius: $container-border-radius;
  outline: 0;
  cursor: pointer;
  transition: $transition;
  transition-property: opacity, transform;

  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }

  &:active {
    transform: scale(0.9);
  }
}
