@font-face {
  font-family: "DINPro";
  src: url('#{$font-dir}/din-pro--regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "DINPro";
  src: url('#{$font-dir}/din-pro--medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "DINPro";
  src: url('#{$font-dir}/din-pro--bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
