@import 'styles/config/variables';
@import 'styles/utils/mixins';

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 30px;

  @include breakpoint(min, width, $small) {
    justify-content: flex-start;
  }

  &__image {
    width: 56px;
    margin-right: 20px;

    @include breakpoint(min, width, $small) {
      margin-right: 30px;
    }

    &--white {
      fill: $white;
    }
  }

  &__title {
    &--white {
      color: $white;
    }
  }
}
