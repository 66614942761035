@import 'styles/config/variables';
@import 'styles/utils/mixins';

$settings-breakpoint: 1200px;

.gradient-generator {
  margin: 30px;

  &__controls {
    margin: 10px;
    padding: 20px;
  }

  &__body {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__column {
    & + & {
      margin: 0 10px;
    }
  }
}
