@import 'styles/config/variables';
@import 'styles/utils/mixins';

.request {
  overflow: hidden;
  margin: 30px 0;
  border-radius: $container-border-radius;
  box-shadow: $container-box-shadow;

  $self: &;

  &__wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;

    @include breakpoint(min, width, $extra-large) {
      flex-wrap: nowrap;
    }
  }

  &__avatar,
  &__author,
  &__info {
    margin: 10px;
  }

  &__avatar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__info {
    max-width: 200px;
  }

  &__avatar {
    flex-shrink: 0;
  }

  &__author-name {
    font-size: 16px;
    line-height: 24px;

    @include breakpoint(min, width, $extra-large) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__author-position {
    font-size: 14px;
    line-height: 20px;

    @include breakpoint(min, width, $extra-large) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__type,
  &__status {
    width: 100%;
  }

  &__type {
    background-color: $black;
  }

  &__type-label,
  &__status-label {
    padding: 10px 30px;
    color: $white;
    font-size: 14px;
    font-weight: 700;
  }

  &__status {
    text-align: center;

    &--pending {
      background-color: $error;
    }

    &--declined {
      background-color: $mine-shaft;
    }

    &--approved {
      background-color: $black;
    }
  }

  &__button {
    @include breakpoint(min, width, $large) {
      margin-left: auto;
    }
  }

  &--dashboard {
    border: 1px solid $alto;
    box-shadow: none;

    #{$self}__wrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    #{$self}__button {
      margin: 10px;
    }
  }
}
