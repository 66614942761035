@import 'styles/config/variables';
@import 'styles/utils/mixins';

.form-input {
  width: 100%;
  padding: 8px 16px;
  border: 1px solid $black;
  border-radius: 4px;
  color: $black;
  background-color: $white;
  font-size: 18px;
  font-weight: 500;
  transition-property: border;
  transition: $transition;

  &::placeholder {
    color: $black;
    font-size: 18px;
  }

  &--system {
    border: none;
    border-bottom: 1px solid $black;
    border-radius: 0;
    font-size: 14px;

    @include breakpoint(min, width, $extra-large) {
      width: 560px;
      font-size: 20px;
    }

    &::placeholder {
      font-size: 14px;

      @include breakpoint(min, width, $extra-large) {
        font-size: 20px;
      }
    }
  }

  &--invalid {
    border-color: $error;

    &::placeholder {
      color: $error;
    }
  }
}
