$header-height: 112px;

.not-found {
  position: relative;
  height: calc(100vh - #{$header-height});

  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &__title {
    font-size: 120px;
    line-height: 120px;
  }

  &__message {
    font-size: 24px;
    line-height: 36px;
  }

  &__button {
    margin-top: 16px;
  }
}
