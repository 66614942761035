// --------------------------------------------------
// GLOBAL STYLES
// --------------------------------------------------
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: $main-font;
  color: $main-color;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
p {
  margin: 0;
}

code,
input,
textarea {
  font-family: $main-font;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $white inset !important;
  }

  &::placeholder {
    color: $silver-rust;
  }
}

select {
  font-family: $main-font;

  option {
    &,
    &::selection {
      background-color: $white;
      color: $black;
    }

    &:checked {
      background: $black;
      color: $white;
    }
  }
}

noscript {
  > div {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    padding: 0 18px;
    background: rgba($error, 0.7);
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    color: $white;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
