.swatches {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 296px;

  &__color {
    margin-right: 4px;
  }
  
  &__input {
    width: 100%;
    margin-top: 10px;
  }
}
