@import 'styles/config/variables';
@import 'styles/utils/mixins';

.announcement-card {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 580px;
  padding: 0;
  border: none;
  border-radius: $container-border-radius;
  background-color: transparent;
  text-align: left;
  box-shadow: $container-box-shadow;
  font-family: $main-font;
  font-size: $content-font-size;
  transition-property: transform;
  transition: $transition;

  $self: &;

  &__avatar-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
  }

  &__avatar {
    margin-right: 20px;
  }

  &__title,
  &__author-name {
    font-size: 18px;
    line-height: 24px;

    @include breakpoint(min, width, $extra-large) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &__title {
    font-weight: 700;
  }

  &__author-position {
    font-size: 16px;
    line-height: 18px;

    @include breakpoint(min, width, $extra-large) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;
    max-height: 300px;
  }

  &__text-wrapper {
    min-height: 150px;
    padding: 20px;
  }

  &__settings {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }

  &__settings-icon {
    opacity: 0.6;
    width: 24px;
    height: 24px;
    color: $black;
    transition-property: transform;
    transition: $transition;
  }

  &__settings-button {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    transition-property: transform;
    transition: $transition;

    &:hover {
      transform: scale(1.1);

      #{$self}__settings-icon {
        opacity: 1;
      }
    }
  }

  &__date {
    margin-top: 20px;
    font-size: 12px;
    text-transform: lowercase;
  }

  &--dashboard {
    justify-content: unset;
    min-height: 420px;
    height: unset;
    box-shadow: unset;

    #{$self}__image {
      display: none;
    }
  }
}
