@import 'styles/config/variables';

.member-card {
  word-wrap: break-word;

  $self: &;

  &__settings {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }

  &__settings-icon {
    opacity: 0.6;
    width: 24px;
    height: 24px;
    color: $black;
    transition-property: transform;
    transition: $transition;
  }

  &__settings-button {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    transition-property: transform;
    transition: $transition;

    &:hover {
      transform: scale(1.1);

      #{$self}__settings-icon {
        opacity: 1;
      }
    }
  }

  &__avatar-wrapper {
    padding: 30px 20px 20px;
  }

  &__avatar {
    height: 160px;
  }

  &__name {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
  }

  &__additional {
    text-align: center;
    font-size: 16px;
    line-height: 26px;
  }

  &__contact-list {
    display: flex;
    justify-content: center;
    max-width: 80%;
    margin: 6px auto;
    list-style-type: none;
  }

  &__contact-item {
    margin: 0 6px;
  }

  &__contact-icon {
    opacity: 0.6;
    transition-property: transform;
    transition: $transition;
  }

  &__contact-button {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: $main-font;
    font-size: 14px;
    background-color: transparent;
    transition-property: transform;
    transition: $transition;

    &:hover {
      #{$self}__contact-icon {
        opacity: 1;
      }
    }
  }

  &__details {
    min-height: 30px;
    margin: 10px 0;
    text-align: center;
  }
}
