@import 'styles/config/variables';
@import 'styles/utils/mixins';

$tablet-colums: 4;
$desktop-columns: 6;
$grid-gutter: 16px;

.tools {
  display: flex;
  flex-wrap: wrap;
  padding: 14px;
  list-style-type: none;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: $grid-gutter;
    padding: 20px;
    box-shadow: $container-box-shadow;
    border-radius: $container-border-radius;
    cursor: pointer;
    font-family: $main-font;
    transition-property: transform;
    transition: $transition;

    @include breakpoint(min, width, $medium) {
      width: calc((100% - #{$tablet-colums} * #{$grid-gutter} * 2) / #{$tablet-colums});
    }

    @include breakpoint(min, width, $extra-large) {
      width: calc((100% - #{$desktop-columns} * #{$grid-gutter} * 2) /#{$desktop-columns});
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0;
    background-color: transparent;
    margin: 0;
    padding: 0;
    font-family: $main-font;
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    border-radius: 50%;
    border: 1px solid $black;

    @include breakpoint(min, width, $extra-large) {
      width: 100px;
      height: 100px;
    }
  }

  &__image {
    width: 50px;
    height: 50px;
  }

  &__link {
    color: $black;
    font-size: 20px;
  }
}
