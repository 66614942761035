@import 'styles/config/variables';

.edit-delete-menu {
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: $container-border-radius;
  background: $black;

  $self: &;

  &__icon {
    color: $alto;
    transition: $transition;
    transition-property: color;

    &--edit {
      width: 32px;
      height: 32px;
      margin-right: 20px;
    }

    &--delete {
      width: 24px;
      height: 28px;
    }
  }

  &__button {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;

    &:hover {
      #{$self}__icon {
        color: $white;

        &--delete {
          color: $reddish-pink;
        }
      }
    }
  }
}
